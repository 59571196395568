import React from "react";
import Typography from '@material-ui/core/Typography';
import {
  Grid,
  TextField,
  CircularProgress
} from '@material-ui/core';

export default class Step4 extends React.Component {

  async componentDidMount() {}

  render() {
    if (this.props.currentStep !== 4) {
      return null
    }
    if (!this.props.transactionComplete) {
      return (
        <React.Fragment>
          <CircularProgress size={20} />
          <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
            Processing Transaction
          </Typography>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        { this.props.transactionSuccess &&
        <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
          The transaction was successful.  You may close this browser tab.
        </Typography> }
        { !this.props.transactionSuccess &&
        <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#aa282a' }} gutterBottom>
          The transaction failed.  Please use the button below to try another payment method.
        </Typography> }
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="success"
              name="success"
              label="Transaction State"
              value={ this.props.transactionSuccess ? 'Succeeded' : 'Failed' }
              fullWidth
              variant="filled"
              error={ !this.props.transactionSuccess }
            />
          </Grid>
          { this.props.transactionSuccess &&
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="transactionId"
              name="transactionId"
              label="Transaction ID"
              value={this.props.transactionId}
              fullWidth
              variant="filled"
              error={ !this.props.transactionSuccess }
            />
            </Grid> }
            { !this.props.transactionSuccess &&
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="transactionFailedReason"
                name="transactionFailedReason"
                label="Failure Reason"
                value={this.props.transactionFailureReason}
                fullWidth
                variant="filled"
                error
              />
              </Grid> }
        </Grid>
      </React.Fragment>
    );

    
  }
}