import React from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react';
import Payment from './Payment';
import Transaction from './Transaction';
import ChangePassword from './ChangePassword';
import {Helmet} from "react-helmet";
import { Loading, SignIn, ConfirmSignIn, Greetings, RequireNewPassword, VerifyContact, ForgotPassword } from 'aws-amplify-react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';


Amplify.configure(awsconfig);


function App() {
  return (
    <div className="App">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Collin Street Bakery Payment Gateway</title>
          <meta name="robots" content="noindex" />
          <link rel="canonical" href="https://pg.collinstreet.com/" />
      </Helmet>
      <BrowserRouter>
        <header className="App-header">
          <Switch>
            <Route path="/change-password">
              <ChangePassword />
            </Route>
            <Route path="/t">
              <Transaction />
            </Route>
            <Route path="/">
              <Payment />
            </Route>
          </Switch>
        </header>
      </BrowserRouter>
    </div>
  );
}

export default withAuthenticator(App, true, [
  <SignIn />,
  <ConfirmSignIn/>,
  <RequireNewPassword/>,
  <Loading/>,
  <VerifyContact />,
  <ForgotPassword />,
  <Greetings />
], null, null, {});
