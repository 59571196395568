/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b9305343-283a-45a6-b96b-1902d483c30d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3Derx4VdH",
    "aws_user_pools_web_client_id": "1shian5ck0eciu89kie0jp2o1a",
    "oauth": {},
    "aws_content_delivery_bucket": "cs-payments-portal-20200111194843-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d6ff0m1skyoo4.cloudfront.net",
    "aws_cloud_logic_custom": [
        {
            "name": "cspaymentportalapi",
            "endpoint": "https://7hih2r36r5.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
