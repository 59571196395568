/**
 * currency.js
 * 
 * Author: David Tucker, MindMill Consulting
 * 
 * This module handles the process of converting string currency values
 * to integers (for example '34.48' to 3448) to allow for us to do math
 * manipulations without running into JavaScript's floating point challengesglobal.fetch = require('node-fetch');
 * 
 * It will also allow us to reconvert those integer values to strings
 * which are expected by Braintree.
 */

/**
 * Converts a currency string to a currency integer
 * @param {String} amount original currency string
 * @returns {Number} the converted integer
 */
module.exports.convertStringToCurrencyInt = (amount) => {
  if(typeof amount !== 'string') {
    throw new Error('Amount must be a string');
  }

  // Find if period is included
  const periodIdx = amount.indexOf('.');

  if(periodIdx > -1) {
    // Verify that period has two characters after it
    if(periodIdx !== amount.length - 3) {
      throw new Error('Period should have two characters after it');
    }
    // Remove period if included
    amount = amount.substr(0, periodIdx) + amount.substr(periodIdx + 1, amount.length);
  }

  // Check to be sure that there isn't another period
  if(amount.indexOf('.') !== -1) {
    throw new Error('Amount may only have a single period');
  }

  // Make sure only numbers left
  if(!amount.match(/^-{0,1}[0-9]+$/)) {
    throw new Error('Amount may only have numbers and a single period with an optional leading minus sign.');
  }

  // Create an Int from the remaining string
  return parseInt(amount);
};

/**
 * Converts a currency integer back to a currency string (for example 3448 
 * to '34.48')
 * @param {Number} currencyInt original currency string
 * @returns {String} the converted currency string
 */
module.exports.convertCurrencyIntToString = (currencyInt) => {
  if(typeof currencyInt !== 'number' || !Number.isInteger(currencyInt)) {
    throw new Error('CurrencyInt must be a number - specifically an integer');
  }

  // Convert to string
  let output = currencyInt.toString();

  // Pad beginning with zeros
  output = output.padStart(3, '0');

  // Insert period and return the new string
  return output.slice(0, output.length - 2) + '.' + output.slice(output.length - 2);
}
