import React from "react";
import Typography from '@material-ui/core/Typography';
import DropIn from "braintree-web-drop-in-react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { API } from "aws-amplify";


export default class Step2 extends React.Component {

  state = {
    clientToken: ''
  };

  card = {
    cardholderName: {
      required: true
    }
  };

  async componentDidMount() {
    // Get a client token for authorization from your server
    const response = await API.get("cspaymentportalapi", "/client-token");
    const clientToken = response.clientToken;
    this.setState({
      clientToken: clientToken
    });
  }

  copyZipCode (event) {
    this.zipField.select();
    document.execCommand('copy');
  }

  render() {
    if (this.props.currentStep !== 2) {
      return null
    } else if (!this.state.clientToken) {
      return <Typography variant="body1">Loading...</Typography>
    } else {
      return (
        <React.Fragment>
          <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
            Please enter the payment method information for this order.  You will need to enter the zip code (which is displayed below):
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                id="zipCode"
                name="zipCode"
                label="Zip Code"
                value={this.props.zipCode}
                onChange={this.props.handleChange}
                fullWidth
                variant="outlined"
                ref={(zipField) => this.zipField = zipField}
              />
              <CopyToClipboard onCopy={this.onCopy} text={this.props.zipCode}>
                <Button>
                  Copy Zip Code
                </Button>
              </CopyToClipboard>
            </Grid>
          </Grid>
          <DropIn
            options={{ authorization: this.state.clientToken }}
            onInstance={this.props.handleDropInUI} />
        </React.Fragment>
        );
    }
  }
}