import React from "react";
import Container from '@material-ui/core/Container'
import { API } from "aws-amplify";
import Button from '@material-ui/core/Button'
import { styled } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  Grid,
  TextField
} from '@material-ui/core';

import {  convertStringToCurrencyInt, convertCurrencyIntToString } from './util/currency';

const FormContainer = styled(Container)({
  padding: '30px',
  color: '#333333'
});

// TODO - need to make this a shared utility
function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); //eslint-disable-line
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const VIEW_STATE = {
  PRE_TRANSACTION_FETCH: 0,
  TRANSACTION_FETCHING: 1,
  TRANSACTION_FETCHED_WITH_ERROR: 2,
  TRANSACTION_UNEDITABLE: 3,
  TRANSACTION_REFUND_GREATER_THAN_TRANSACTION: 4,
  TRANSACTION_FETCHED_SUCCESSFULLY: 5,
  TRANSACTION_EDITING: 6,
  TRANSACTION_EDITED_WITH_ERROR: 7,
  TRANSACTION_EDITED_SUCCESSFULLY: 8
};

export default class Transaction extends React.Component {

  state = {
    transactionId: "", 
    orderId: "",
    adjustmentAmount: "",
    transaction: {},
    currentState: VIEW_STATE.PRE_TRANSACTION_FETCH,
    actionMessage: '',
    actionAmount: 0
  };

  async componentDidMount() {
    let transactionId = getUrlParameter('transactionid') || getUrlParameter('t');
    let orderId = getUrlParameter('orderid') || getUrlParameter('o') || "0";
    let adjustmentAmount = getUrlParameter('adjustmentamount') || getUrlParameter('n');
    let chargeType = getUrlParameter('chargeType').toLowerCase() || getUrlParameter('x').toLowerCase() || "c";
    let accountNumber = getUrlParameter('accountNumber')|| getUrlParameter('w') || "0";

    this.setState({
      transactionId: transactionId,
      orderId: orderId,
      adjustmentAmount: adjustmentAmount,
      chargeType: chargeType,
      accountNumber: accountNumber
    }, () => {
      if(this.isValid()) {
        this.fetchTransactionDetails();
      }
    });
  }

  handleChange(event) {
    const {name, value} = event.target
    this.setState({
      [name]: value
    }, () => {
      this.updateActionDetails();
    }); 
  }

  isAmountValid() {
    const amountRegexp = /^-{0,1}[0-9]+\.[0-9]{2}$/;
    return amountRegexp.test(this.state.adjustmentAmount);
  }

  isOrderIdValid() {
    const orderIdRegexp = /^[0-9]{2,}$/;
    return orderIdRegexp.test(this.state.orderId);
  }

  isTransactionIdValid() {
    const transactionIdRegexp = /^[0-9a-zA-Z]{3,}$/;
    return transactionIdRegexp.test(this.state.transactionId);
  }

  isAccountNumberValid() {
    const accountNumberRegexp = /^[0-9]{2,}$/; 
    return accountNumberRegexp.test(this.state.accountNumber);
  }

  isAccountNumberPresent() {
    return (this.state.accountNumber && this.state.accountNumber.length > 2);
  }

  isValid() {
     if(this.isAccountNumberPresent()) {
      return this.isAmountValid() && this.isAccountNumberValid() && this.isTransactionIdValid();
     } 
    return this.isAmountValid() && this.isOrderIdValid() && this.isTransactionIdValid();
  }

  updateActionDetails() {
    // Only update if we have a transaction
    if(this.currentState < VIEW_STATE.TRANSACTION_FETCHED_SUCCESSFULLY) {
      return;
    }

    // If invalid - don't do anything
    if(!this.isValid()) {
      this.setState({
        actionMessage: '',
        actionAmount: ''
      });
      return;
    }
    
    // If valid, calculate it
    let originalAmount, adjustmentAmount;
    try {
      originalAmount = convertStringToCurrencyInt(this.state.transaction.amount);
      adjustmentAmount = convertStringToCurrencyInt(this.state.adjustmentAmount);
    } catch(err) {
      return;
    }
    if((originalAmount + adjustmentAmount) === 0) {
      this.setState({
        actionMessage: 'a Full Refund',
        actionAmount: convertCurrencyIntToString(originalAmount)
      });
    } else if ((originalAmount + adjustmentAmount) < 0) {
      this.setState({
        currentState: VIEW_STATE.TRANSACTION_REFUND_GREATER_THAN_TRANSACTION
      });
    } else if(adjustmentAmount < 0) {
      this.setState({
        actionMessage: 'a Partial Refund',
        actionAmount: convertCurrencyIntToString(Math.abs(adjustmentAmount))
      });
    } else if(adjustmentAmount > 0) {
      this.setState({
        actionMessage: 'an Additional Charge',
        actionAmount: convertCurrencyIntToString(adjustmentAmount)
      });
    }
  }

  async fetchTransactionDetails() {
    this.setState({
      currentState: VIEW_STATE.TRANSACTION_FETCHING
    });
    let api_params = {
      body: {
        id: this.state.transactionId
      }
    };
    let response;
    try {
      response = await API.post("cspaymentportalapi", "/transaction", api_params);
      if(response.editable !== true) {
        this.setState({
          currentState: VIEW_STATE.TRANSACTION_UNEDITABLE,
          transaction: response
        })
      } else {
        this.setState({
          currentState: VIEW_STATE.TRANSACTION_FETCHED_SUCCESSFULLY,
          transaction: response
        }, () => {
          this.updateActionDetails();
        });
      }
    } catch(err) {
      console.dir(err);
      this.setState({
        currentState: VIEW_STATE.TRANSACTION_FETCHED_WITH_ERROR
      })
    }
  }

  async editTransaction() {
    this.setState({
      currentState: VIEW_STATE.TRANSACTION_EDITING
    })
    let api_params = {
      body: {
        transaction_id: this.state.transactionId,
        adjustment_amount: this.state.adjustmentAmount,
        order_id: this.state.orderId,
        charge_type: this.state.chargeType,
        account_number: this.state.accountNumber
      }
    };
    try {
      await API.post("cspaymentportalapi", "/change-amount", api_params);
      this.setState({
        currentState: VIEW_STATE.TRANSACTION_EDITED_SUCCESSFULLY
      })
    } catch(err) {
      this.setState({
        currentState: VIEW_STATE.TRANSACTION_EDITED_WITH_ERROR
      })
    }
  }

  render() {
    return (
      <FormContainer maxWidth="md">
        <Card>
          { this.state.currentState === VIEW_STATE.TRANSACTION_EDITED_SUCCESSFULLY &&
          <React.Fragment>
            <CardHeader title='Success' />
            <Divider />
            <CardContent>
              <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
                The transaction has been edited.  You may close your browser window.
              </Typography>
            </CardContent>
          </React.Fragment> }

          { this.state.currentState !== VIEW_STATE.TRANSACTION_EDITED_SUCCESSFULLY &&
          <React.Fragment>
            <CardHeader title='Edit Transaction' />
            <Divider />
            <CardContent>
              <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
                Enter the following information to edit a transaction:
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    disabled={this.state.currentState >= VIEW_STATE.TRANSACTION_FETCHED_SUCCESSFULLY}
                    error={!this.isTransactionIdValid()}
                    id="transactionId"
                    name="transactionId"
                    label="Transaction ID"
                    value={this.state.transactionId}
                    onChange={this.handleChange.bind(this)}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  { this.isAccountNumberPresent() && 
                  <TextField
                    required
                    disabled={this.state.currentState >= VIEW_STATE.TRANSACTION_FETCHED_SUCCESSFULLY}
                    error={!this.isAccountNumberValid()}
                    id="accountNumber"
                    name="accountNumber"
                    label="Account Number"
                    value={this.state.accountNumber}
                    onChange={this.handleChange.bind(this)}
                    fullWidth
                    variant="outlined"
                  /> }
                  { !this.isAccountNumberPresent() && 
                  <TextField
                    required
                    disabled={this.state.currentState >= VIEW_STATE.TRANSACTION_FETCHED_SUCCESSFULLY}
                    error={!this.isOrderIdValid()}
                    id="orderId"
                    name="orderId"
                    label="Order ID"
                    value={this.state.orderId}
                    onChange={this.handleChange.bind(this)}
                    fullWidth
                    variant="outlined"
                  /> }
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    disabled={this.state.currentState >= VIEW_STATE.TRANSACTION_FETCHED_SUCCESSFULLY}
                    error={!this.isAmountValid()}
                    id="adjustmentAmount"
                    name="adjustmentAmount"
                    label="Transaction Adjustment Amount"
                    value={this.state.adjustmentAmount}
                    onChange={this.handleChange.bind(this)}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            { this.state.currentState === VIEW_STATE.TRANSACTION_FETCHING &&
            <React.Fragment>
              <Divider />
              <CardContent>
                <CircularProgress size={20} />
                <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
                  Fetching Transaction ...
                </Typography>
              </CardContent>
            </React.Fragment> }
            { this.state.currentState === VIEW_STATE.TRANSACTION_UNEDITABLE &&
            <React.Fragment>
              <Divider />
              <CardContent>
                <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#FF0000' }} gutterBottom>
                  This transaction is not in an editable state.  It's current status is <strong>{ this.state.transaction.status }</strong>.  If you need to change this transaction, you will need to utilize the Braintree portal.
                </Typography>
              </CardContent>
            </React.Fragment> }
            { this.state.currentState === VIEW_STATE.TRANSACTION_FETCHED_WITH_ERROR &&
            <React.Fragment>
              <Divider />
              <CardContent>
                <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#FF0000' }} gutterBottom>
                  We could not fetch the transaction with this information.  You may try again, but make sure that this the correct transaction number.
                </Typography>
              </CardContent>
            </React.Fragment> }
            { this.state.currentState >= VIEW_STATE.TRANSACTION_FETCHED_SUCCESSFULLY &&
            <React.Fragment>
              <Divider />
              <CardContent>
                <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
                  Original Transaction Details from Braintree:
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled
                      id="createdDate"
                      name="createdDate"
                      label="Date Created"
                      value={new Date(this.state.transaction.createdAt).toLocaleDateString()}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled
                      id="status"
                      name="status"
                      label="Transaction Status"
                      value={this.state.transaction.status}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled
                      id="amount"
                      name="amount"
                      label="Original Amount"
                      value={this.state.transaction.amount}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      disabled
                      id="cardType"
                      name="cardType"
                      label="Card Type"
                      value={this.state.transaction.creditCard.cardType}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      disabled
                      id="maskedNumber"
                      name="maskedNumber"
                      label="Credit Card (Masked)"
                      value={this.state.transaction.creditCard.maskedNumber}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </React.Fragment> }
            { this.state.currentState === VIEW_STATE.TRANSACTION_FETCHED_SUCCESSFULLY && this.isValid() &&
            <React.Fragment>
              <Divider />
              <CardContent>
                <Typography variant="body1" style={{ fontStyle: 'italic', color: '#333333' }} gutterBottom>
                  This transaction edit would result in <strong>{ this.state.actionMessage }</strong> of <strong>${ this.state.actionAmount }</strong> to the customer.
                </Typography>
              </CardContent>
            </React.Fragment> }
            { this.state.currentState === VIEW_STATE.TRANSACTION_EDITING &&
            <React.Fragment>
              <Divider />
              <CardContent>
                <CircularProgress size={20} />
                <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
                  Editing Transaction ...
                </Typography>
              </CardContent>
            </React.Fragment> }
            { this.state.currentState === VIEW_STATE.TRANSACTION_EDITED_WITH_ERROR &&
            <React.Fragment>
              <Divider />
              <CardContent>
                <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#FF0000' }} gutterBottom>
                  We could not edit the transaction at this time.  To avoid any duplicate refunds or duplicate charges, please have a supervisor utilize the Braintree portal for this one transaction.
                </Typography>
              </CardContent>
            </React.Fragment> }
            { this.state.currentState === VIEW_STATE.TRANSACTION_REFUND_GREATER_THAN_TRANSACTION &&
            <React.Fragment>
              <Divider />
              <CardContent>
                <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#FF0000' }} gutterBottom>
                  This refund is greater than the original transaction amount.  If this is needed, the refund will need to be entered by a supervisor into the Braintree portal.
                </Typography>
              </CardContent>
            </React.Fragment> }
            { this.state.currentState === VIEW_STATE.PRE_TRANSACTION_FETCH &&
            <React.Fragment>
              <Divider />
              <CardActions style={{ padding: '16px', textAlign: 'right', display: 'block' }}>
                <Button variant="contained" disabled={!this.isValid()} style={{ marginRight: '10px' }}
                  disableElevation onClick={this.fetchTransactionDetails.bind(this)}>
                  Get Transaction Details
                </Button> 
              </CardActions>
            </React.Fragment> }
            { this.state.currentState === VIEW_STATE.TRANSACTION_FETCHED_SUCCESSFULLY &&
            <React.Fragment>
              <Divider />
              <CardActions style={{ padding: '16px', textAlign: 'right', display: 'block' }}>
                <Button variant="contained" color="primary" disabled={!this.isValid() || this.currentState === VIEW_STATE.TRANSACTION_FETCHING } style={{ marginRight: '10px' }}
                  disableElevation onClick={this.editTransaction.bind(this)}>
                  Confirm Transaction Edit
                </Button> 
              </CardActions>
            </React.Fragment> }
          </React.Fragment> }
        </Card>
      </FormContainer>
    );
  }
}
