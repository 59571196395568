import React from "react";
import { API } from "aws-amplify";
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Step1 from './views/Step1';
import Step2 from './views/Step2';
import Step3 from './views/Step3';
import Step4 from './views/Step4';
import { styled } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  CircularProgress,
} from '@material-ui/core';

const FormContainer = styled(Container)({
  padding: '30px',
  color: '#333333'
});

function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); //eslint-disable-line
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default class Payment extends React.Component {

  state = {
    currentStep: 0,
    clientToken: "", 
    nonce: "",
    orderId: "",
    accountNumber: "",
    amount: "",
    zipCode: "",
    dropInUI: "",
    country: "",
    fetchingNonce: false,
    transactionComplete: false,
    transactionSuccess: false,
    isEditable: true,
    transactionId: '',
    transactionFailureReason: ''
  };

  async componentDidMount() {
    // Set Order ID to 0 if not found
    let orderId = getUrlParameter('orderId') || getUrlParameter('o') || "0";
    let chargeType = getUrlParameter('chargeType').toLowerCase() || getUrlParameter('x').toLowerCase() || "c";
    let amount = getUrlParameter('amount')|| getUrlParameter('a');
    let accountNumber = getUrlParameter('accountNumber')|| getUrlParameter('w') || "0";
    let zipCode = getUrlParameter('zipCode') || getUrlParameter('z');
    // Selects US by default if either country or c value isn't present
    let country = getUrlParameter('country') || getUrlParameter('c') || 840;

    let isEditable = !this.areFullParametersPresent({ chargeType, amount, zipCode })

    this.setState({
      currentStep: 1,
      orderId: orderId,
      chargeType: chargeType,
      accountNumber: accountNumber,
      amount: amount,
      zipCode: zipCode,
      country: country,
      isEditable: isEditable
    });
  }

  areFullParametersPresent(params) {
    if(params.chargeType && params.amount && params.zipCode) {
      return true
    }
    return false
  }

  handleChange(event) {
    const {name, value} = event.target
    this.setState({
      [name]: value
    })    
  }

  handleDropInUI(instance) {
    this.setState({
      dropInUI: instance
    }) 
  }

  handlePaymentNonce(nonce) {
    this.setState({
      nonce: nonce
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { email, username, password } = this.state
    alert(`Your registration detail: \n 
      Email: ${email} \n 
      Username: ${username} \n
      Password: ${password}`)
  }

  async getPaymentNonce() {
    this.setState({
      fetchingNonce: true
    })
    console.log("Get Nonce");
    const { nonce } = await this.state.dropInUI.requestPaymentMethod();
    console.log("Nonce Retrieved");
    console.log("NONCE: " + nonce);
    this.setState({
      fetchingNonce: false,
      nonce: nonce,
      currentStep: 3
    })

  }

  async processTransaction() {
    this.setState({
      currentStep: 4
    })
    let api_params = {
      body: {
        nonce: this.state.nonce,
        amount: parseFloat(this.state.amount),
        countryCode: parseInt(this.state.country),
        charge_type: this.state.chargeType.toLowerCase(),
        order_id: this.state.orderId,
        account_number: this.state.accountNumber
      }
    };

    let checkout_response;
    try {
      checkout_response = await API.post("cspaymentportalapi", "/checkout", api_params);
      this.setState({
        transactionComplete: true,
        transactionSuccess: checkout_response.success,
        transactionId: checkout_response.transaction.id,
        transactionFailureReason: checkout_response.message
      })
    } catch(err) {
      this.setState({
        transactionComplete: true,
        transactionSuccess: false
      })
    }
  }

  next = (event) => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep + 1;
    this.setState({
      currentStep: currentStep
    })
  }

  prev = (event) => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep - 1;
    this.setState({
      currentStep: currentStep
    })
  }

  getTitle = () => {
    if(this.state.currentStep === 1) {
      switch (this.state.chargeType) {
        case 'a':
          return 'Customer Account Charge';
        case 'b':
          return 'Clean Order Charge';
        case 'c':
          return 'Pending Order Charge';
        default:
          // Fall through
          return 'Transaction';
      }
    }
    switch(this.state.currentStep) {
      case 2:
        return 'Payment Information';
      case 3:
        return 'Process Transaction';
      case 4:
        return 'Transaction Result';
      default:
        // Fall Through
    }
  }

  backToPaymentMethod = (event) => {
    this.setState({
      currentStep: 2,
      nonce: '',
      fetchingNonce: false,
      transactionComplete: false,
      transactionSuccess: false,
      transactionId: '',
      transactionFailureReason: ''
    });
  }

  render() {
    return (
      <FormContainer maxWidth="md">
        <Card>
          <CardHeader title={this.getTitle()} />
          <Divider />
          <CardContent>
              <Step1
                currentStep={this.state.currentStep} 
                accountNumber={this.state.accountNumber}
                isEditable={this.state.isEditable}
                zipCode={this.state.zipCode}
                amount={this.state.amount}
                orderId={this.state.orderId}
                country={this.state.country}
                handleChange={this.handleChange.bind(this)}
                />
              <Step2
                currentStep={this.state.currentStep}
                isEditable={this.state.isEditable}
                zipCode={this.state.zipCode}
                handleDropInUI={this.handleDropInUI.bind(this)} />
              <Step3
                currentStep={this.state.currentStep}
                zipCode={this.state.zipCode}
                isEditable={this.state.isEditable}
                amount={this.state.amount}
                orderId={this.state.orderId}
                nonce={this.state.nonce}
                accountNumber={this.state.accountNumber}
                 />
              <Step4
                currentStep={this.state.currentStep}
                isEditable={this.state.isEditable}
                transactionComplete={this.state.transactionComplete}
                transactionSuccess={this.state.transactionSuccess}
                transactionId={this.state.transactionId}
                transactionFailureReason={this.state.transactionFailureReason} />
          </CardContent>
          <Divider />
          <CardActions style={{ padding: '16px', textAlign: 'right', display: 'block' }}>
            { this.state.currentStep === 1 &&
            <Button variant="contained" style={{ marginRight: '10px' }} disableElevation onClick={this.next}>
              Next
            </Button> }
            { this.state.currentStep > 1 && this.state.currentStep < 4 &&
            <Button variant="contained" style={{ marginRight: '10px' }} disableElevation onClick={this.prev}>
              Previous
            </Button> }
            { this.state.currentStep === 2 && this.state.nonce === '' &&
            <Button variant="contained" style={{ marginRight: '10px' }} onClick={this.getPaymentNonce.bind(this)} disableElevation>
              Submit Payment Method
            </Button> }
            { this.state.currentStep === 2 && this.state.fetchingNonce &&
              <CircularProgress size={20} /> }
            { this.state.currentStep === 3 &&
            <Button variant="contained" style={{ marginRight: '10px' }} color="primary" onClick={this.processTransaction.bind(this)} disableElevation>
              Authorize Transaction
            </Button> }
            { this.state.currentStep === 4 && !this.state.transactionSuccess &&
            <Button variant="contained" style={{ marginRight: '10px' }} color="primary" onClick={this.backToPaymentMethod.bind(this)} disableElevation>
              Re-Enter Payment Method
            </Button> }
            
          </CardActions>
        </Card>
      </FormContainer>
    );
  }
}
