import React from "react";
import Container from '@material-ui/core/Container'
import { API } from "aws-amplify";
import Button from '@material-ui/core/Button'
import { styled } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  Grid,
  TextField
} from '@material-ui/core';

const FormContainer = styled(Container)({
  padding: '30px',
  color: '#333333'
});

// TODO - need to make this a shared utility
function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); //eslint-disable-line
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const VIEW_STATE = {
  PRE_CHANGE_PASSWORD_CALL: 0,
  CHANGE_PASSWORD_CALL_FETCHING: 1,
  CHANGE_PASSWORD_CALL_WITH_ERROR: 2,
  CHANGE_PASSWORD_CALL_SUCCESS: 3
};

export default class ChangePassword extends React.Component {

  state = {
    currentState: VIEW_STATE.PRE_CHANGE_PASSWORD_CALL,
    email: '',
    newPassword: '',
    confirmNewPassword: '',
    errorMessage: ''
  };

  async componentDidMount() {
    let email = getUrlParameter('email') || getUrlParameter('e');

    this.setState({
      email: email
    });
  }

  handleChange(event) {
    const {name, value} = event.target
    this.setState({
      [name]: value
    }); 
  }

  isEmailValid() {
    //eslint-disable-next-line
    const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegexp.test(this.state.email);
  }

  isNewPasswordValid() {
    const passwordRegexp = /^\S{8,24}$/;
    return passwordRegexp.test(this.state.newPassword);
  }

  doPasswordsMatch() {
    return this.state.newPassword === this.state.confirmNewPassword;
  }

  isValid() {
    return this.isEmailValid() && this.isNewPasswordValid() && this.doPasswordsMatch();
  }

  async updatePassword() {
    this.setState({
      currentState: VIEW_STATE.CHANGE_PASSWORD_CALL_FETCHING,
      errorMessage: ''
    })
    let api_params = {
      body: {
        email: this.state.email,
        newPassword: this.state.newPassword
      }
    };
    try {
      await API.post("cspaymentportalapi", "/change-password", api_params);
      this.setState({
        currentState: VIEW_STATE.CHANGE_PASSWORD_CALL_SUCCESS
      })
    } catch(err) {
      console.dir(err);
      this.setState({
        currentState: VIEW_STATE.CHANGE_PASSWORD_CALL_WITH_ERROR,
        errorMessage: err.response.data.message || 'Cannot Complete Operation'
      })
    }
  }

  render() {
    return (
      <FormContainer maxWidth="md">
        <Card>
          { this.state.currentState === VIEW_STATE.CHANGE_PASSWORD_CALL_SUCCESS &&
          <React.Fragment>
            <CardHeader title='Success' />
            <Divider />
            <CardContent>
              <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
                The user's password has been updated.  You may close your browser window.
              </Typography>
            </CardContent>
          </React.Fragment> }

          { this.state.currentState !== VIEW_STATE.CHANGE_PASSWORD_CALL_SUCCESS &&
          <React.Fragment>
            <CardHeader title='Edit User Password' />
            <Divider />
            <CardContent>
              <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
                Enter the following information to update a user's password. This action will only be able to succeed if your user is listed as an admin. Please enter the following information for the user you wish to update:
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    disabled={this.state.currentState === VIEW_STATE.CHANGE_PASSWORD_CALL_FETCHING}
                    error={!this.isEmailValid()}
                    id="email"
                    name="email"
                    label="User's Email"
                    value={this.state.email}
                    onChange={this.handleChange.bind(this)}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    disabled={this.state.currentState === VIEW_STATE.CHANGE_PASSWORD_CALL_FETCHING}
                    error={!this.isNewPasswordValid()}
                    id="newPassword"
                    name="newPassword"
                    label="New Password"
                    value={this.state.newPassword}
                    onChange={this.handleChange.bind(this)}
                    fullWidth
                    variant="outlined"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    disabled={this.state.currentState === VIEW_STATE.CHANGE_PASSWORD_CALL_FETCHING}
                    error={!this.doPasswordsMatch()}
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    label="Confirm New Password"
                    value={this.state.confirmNewPassword}
                    onChange={this.handleChange.bind(this)}
                    fullWidth
                    variant="outlined"
                    type="password"
                  />
                </Grid>
              </Grid>
            </CardContent>
            { this.state.currentState === VIEW_STATE.CHANGE_PASSWORD_CALL_FETCHING &&
            <React.Fragment>
              <Divider />
              <CardContent>
                <CircularProgress size={20} />
                <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
                  Attempting to update password ...
                </Typography>
              </CardContent>
            </React.Fragment> }
            { this.state.currentState === VIEW_STATE.CHANGE_PASSWORD_CALL_WITH_ERROR &&
            <React.Fragment>
              <Divider />
              <CardContent>
                <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#FF0000' }} gutterBottom>
                  We could not update the user's password at this time. {this.state.errorMessage}
                </Typography>
              </CardContent>
            </React.Fragment> }
            { this.state.currentState < VIEW_STATE.CHANGE_PASSWORD_CALL_SUCCESS &&
            <React.Fragment>
              <Divider />
              <CardActions style={{ padding: '16px', textAlign: 'right', display: 'block' }}>
                <Button variant="contained" color="primary" disabled={!this.isValid() || this.currentState === VIEW_STATE.CHANGE_PASSWORD_CALL_FETCHING } style={{ marginRight: '10px' }}
                  disableElevation onClick={this.updatePassword.bind(this)}>
                  Update User Password
                </Button> 
              </CardActions>
            </React.Fragment> }
          </React.Fragment> }
        </Card>
      </FormContainer>
    );
  }
}
