import React from "react";
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  Grid,
  TextField
} from '@material-ui/core';
import countries from '../data/countries';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default class Step1 extends React.Component {
  instance;

  async componentDidMount() {
  }

  hasValidAccountNumber() {
    return (this.props.accountNumber && this.props.accountNumber.length > 2);
  }

  render() {
    if (this.props.currentStep !== 1) {
      return null
    }
    return (
      <React.Fragment>
        <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
          Please ensure that the transaction information is correct before proceeding to the next page to enter the payment information.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
          { !this.hasValidAccountNumber() &&
            <TextField
              required
              disabled={!this.props.isEditable}
              id="orderId"
              name="orderId"
              label="Order ID"
              value={this.props.orderId}
              onChange={this.props.handleChange}
              fullWidth
              variant="outlined"
          /> }
          { this.hasValidAccountNumber() &&
            <TextField
              required
              disabled={!this.props.isEditable}
              id="accountNumber"
              name="accountNumber"
              label="Account Number"
              value={this.props.accountNumber}
              onChange={this.props.handleChange}
              fullWidth
              variant="outlined"
          /> }
          </Grid> 
          <Grid item xs={12} sm={4}>
            <TextField
              disabled={!this.props.isEditable}
              id="zipCode"
              name="zipCode"
              label="Zip Code"
              value={this.props.zipCode}
              onChange={this.props.handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              disabled={!this.props.isEditable}
              id="amount"
              name="amount"
              label="Transaction Amount"
              value={this.props.amount}
              onChange={this.props.handleChange}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Country
            </InputLabel>
            <Select
              name="country"
              style={{ width: '100%' }}
              labelId="demo-simple-select-outlined-label"
              id="country"
              value={this.props.country}
              onChange={this.props.handleChange}
              labelWidth={200}>
              {countries.map((item, index) => {
                return <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          </Grid>
        </Grid>
      </React.Fragment>
      );
  }
}