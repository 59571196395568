import React from "react";
import Typography from '@material-ui/core/Typography';
import {
  Grid,
  TextField
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';


export default class Step3 extends React.Component {

  state = {
    transactionComplete: false,
    transactionSuccess: false,
    transactionId: ''
  };

  async componentDidMount() {

  }

  hasValidAccountNumber() {
    return (this.props.accountNumber && this.props.accountNumber.length > 2);
  }

  render() {
    if (this.props.currentStep !== 3) {
      return null
    }
    return (
      <React.Fragment>
        <Typography variant="body1" style={{ paddingBottom: '30px', fontStyle: 'italic', color: '#333333' }} gutterBottom>
          When you click Authorize Transaction, the following information will be sent to the payment processor.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            { !this.hasValidAccountNumber() && 
            <TextField
              required
              id="orderId"
              name="orderId"
              label="Order ID"
              value={this.props.orderId}
              disabled
              fullWidth
              variant="outlined"
            /> }
            { this.hasValidAccountNumber() &&
            <TextField
              required
              disabled
              id="accountNumber"
              name="accountNumber"
              label="Account Number"
              value={this.props.accountNumber}
              fullWidth
              variant="outlined"
          /> }
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="zipCode"
              name="zipCode"
              label="Zip Code"
              value={this.props.zipCode}
              disabled
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="amount"
              name="amount"
              label="Transaction Amount"
              value={this.props.amount}
              fullWidth
              disabled
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="nonce"
              name="nonce"
              label="Paymemnt Nonce"
              value={this.props.nonce}
              fullWidth
              disabled
              variant="outlined"
            />
          </Grid>
        </Grid>
      </React.Fragment>
      );
  }
}